<template>
  <div >

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import $ from "jquery";

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data(){
    return {
      lock:0,
    }
  },
  methods:{

  },
}
</script>

<style>

</style>